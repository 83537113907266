<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.29289 15.7074L1.29289 14.7074C0.902369 14.3168 0.902369 13.6837 1.29289 13.2932C1.68342 12.9026 2.31658 12.9026 2.70711 13.2932L3 13.586L7.41245 9.1736C8.16188 8.42324 9.17861 8.0012 10.2391 8.00026L13 8.00026C13.7957 8.00026 14.5587 8.31633 15.1213 8.87894C15.3069 9.0645 15.4656 9.27186 15.595 9.495L17.9091 6.78139C17.9151 6.77432 17.9213 6.76732 17.9275 6.76041C18.421 6.21336 19.1055 5.87625 19.84 5.81845C20.5745 5.76065 21.3032 5.98655 21.8762 6.44968C22.4492 6.91281 22.823 7.57794 22.9205 8.30823C23.0178 9.03668 22.833 9.7747 22.4039 10.3712L18.207 16.3069C17.8404 16.826 17.355 17.251 16.7918 17.5454C16.2283 17.8399 15.6028 17.9958 14.967 18.0002L14.96 18.0003L9.83 18.0003C9.69804 18.0002 9.56658 18.0262 9.44472 18.0768C9.32287 18.1274 9.21224 18.2017 9.11921 18.2953L9.11711 18.2974L8.41421 19.0003L8.70711 19.2932C9.09763 19.6837 9.09763 20.3168 8.70711 20.7074C8.31658 21.0979 7.68342 21.0979 7.29289 20.7074L6.29289 19.7074L2.29289 15.7074ZM19.9969 7.81229C20.2213 7.79463 20.444 7.86365 20.6191 8.00516C20.7941 8.14667 20.9083 8.34991 20.9381 8.57305C20.968 8.7962 20.9111 9.02225 20.7793 9.20476L16.573 15.1536C16.3897 15.4133 16.1471 15.6256 15.8654 15.7729C15.5846 15.9196 15.273 15.9975 14.9562 16.0003H9.83079C9.43493 15.9999 9.04291 16.078 8.67734 16.2299C8.31223 16.3816 7.98071 16.604 7.70184 16.8842L7.70079 16.8853L7 17.586L4.41421 15.0003L8.82755 10.5869C9.20215 10.2119 9.71032 10.0009 10.2404 10.0003H13C13.2652 10.0003 13.5196 10.1056 13.7071 10.2932C13.8946 10.4807 14 10.735 14 11.0003C14 11.2169 13.9297 11.4263 13.8018 11.5979L13.7391 11.6714C13.733 11.6786 13.727 11.6858 13.7211 11.693L13.7071 11.7074C13.5196 11.8949 13.2652 12.0003 13 12.0003H10.5C9.94772 12.0003 9.5 12.448 9.5 13.0003C9.5 13.5525 9.94772 14.0003 10.5 14.0003H13C13.7957 14.0003 14.5587 13.6842 15.1213 13.1216C15.2046 13.0383 15.2825 12.9506 15.3548 12.859L19.4202 8.09173C19.5703 7.92951 19.7762 7.82965 19.9969 7.81229Z"
    />
  </svg>
</template>
